<div class="hidden lg:block section-results pt-20 pb-12">
  <div class="container mx-auto px-3">
    <div class="flex flex-wrap items-center justify-center">
      <div class="w-5/12" data-aos="fade-up">
        <h2 class="text-5xl font-bold">Nuestros resultados</h2>
        <p>Inteligencia Artificial a su servicio.</p>
      </div>
      <div class="w-5/12">
        <div class="flex flex-wrap">
          <div class="w-1/2 px-4">
            <div data-aos="fade-up" data-aos-delay="" class="flex items-center my-12">
              <img src="/assets/images/llamadas-icon.svg" alt="" class="mr-3">
              <div>
                <span class="block font-bold text-3xl">100,000+</span>
                <p>Llamadas al día</p>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="200" class="flex items-center my-12">
              <img src="/assets/images/clientes-icon.svg" alt="" class="mr-3">
              <div>
                <span class="block font-bold text-3xl">+100</span>
                <p>Clientes</p>
              </div>
            </div>
          </div>
          <div class="w-1/2 px-4">
            <div data-aos="fade-up" data-aos-delay="400" class="flex items-center my-12">
              <img src="/assets/images/deudores-icon.svg" alt="" class="mr-3">
              <div>
                <span class="block font-bold text-3xl">2 Millones</span>
                <p>Deudores contactos</p>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="600" class="flex items-center my-12">
              <img src="/assets/images/clientes-icon.svg" alt="" class="mr-3">
              <div>
                <span class="block font-bold text-3xl">+100</span>
                <p>Clientes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
