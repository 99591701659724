<div class="section-sponsors relative">
  <div class="container mx-auto px-3">
    <div class="w-full -mt-20 mb-12 lg:hidden">
      <img src="/assets/images/soluciones-ganadoras.svg" class="mx-auto w-44" alt="">
    </div>
    <div class="w-full lg:w-11/12 text-center lg:pr-12 lg:-mt-20">
      <p class="text-center text-xs">
        Menciones de Prensa
      </p>
      <img src="/assets/images/logos-prensa.png" alt="" class="mx-auto">
    </div>
    <p class="text-center text-xs mb-8 mt-32 hidden lg:block">
      Creado por CEOL Technology y MDP Consulting con reconocimiento de:
    </p>
    <div class="hidden lg:flex flex-wrap items-center justify-center">
      <img src="/assets/images/innovate-logo.png" data-aos="fade-up" class="w-36 mx-8 lg:mx-24" alt="">
      <img src="/assets/images/concytec-logo.png" data-aos="fade-up"  data-aos-delay="200" class="w-36 mx-8 lg:mx-24" alt="">
      <img src="/assets/images/creatividad-empresarial-logo.png" data-aos="fade-up" data-aos-delay="400"  class="w-36 mx-8 lg:mx-24" alt="">
    </div>
  </div>
</div>
