<nav class="responsive-nav" [class.show]="showResponsiveMenu">
  <div class="container m-auto px-6">
    <div class="w-full flex items-center" style="height: var(--header-height);">
      <button class="mr-2" (click)="setShowResponsiveMenu(false)">
        <img src="/assets/images/close-menu-icon.svg" class="w-5">
      </button>
    </div>
    <ul class="mt-8 mb-10">
      <li class="mb-6">
        <a href="#top" class="font-heading text-lg" (click)="setShowResponsiveMenu(false)">Inicio</a>
      </li>
      <li class="mb-6">
        <a href="#como-funciona" class="font-heading text-lg" (click)="setShowResponsiveMenu(false)">¿Cómo funciona?</a>
      </li>
      <li class="mb-6">
        <a href="#planes" class="font-heading text-lg" (click)="setShowResponsiveMenu(false)">Planes y precios</a>
      </li>
      <li class="mb-6">
        <a href="#" class="font-heading text-lg">Conversa con un experto</a>
      </li>
      <li class="mb-6">
        <a href="#" class="font-heading text-lg">¿Quiénes somos?</a>
      </li>
    </ul>
  </div>
</nav>

<header class="main-header bg-white">
  <div class="container m-auto px-6 flex items-center">
    <button class="mr-2 lg:hidden" (click)="setShowResponsiveMenu(true)">
      <img src="/assets/images/open-menu-icon.svg" class="w-5">
    </button>
    <img src="/assets/images/logo-notifai.png" class="h-10 md:h-14" alt="Logo">
    <nav class="main-nav mx-auto hidden lg:block">
      <ul class="main-menu">
        <li>
          <a href="#top">Inicio</a>
        </li>
        <li>
          <a href="#planes">Precio</a>
        </li>
        <li>
          <a href="#como-funciona">¿Cómo funciona?</a>
        </li>
        <li>
          <a href="#blog">Blog</a>
        </li>
      </ul>
    </nav>
    <a href="#" class="btn btn-primary hidden lg:block">Solicita Prueba Gratuita</a>
  </div>
</header>

