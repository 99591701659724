<div class="section-how-it-works pt-16">
  <div id="como-funciona" class="container sm:max-w-md md:max-w-none mx-auto px-6 lg:px-3">
    <h2 data-aos="fade-up" class="text-4xl lg:text-5xl font-bold leading-tight text-center mb-12">¿Cómo funciona Notifai?</h2>
    <div class="flex flex-wrap justify-center -mx-4 lg:-mx-0">
      <div data-aos="fade-up" data-aos-delay="" class="w-full md:w-4/12 lg:w-3/12 text-center px-4 mb-8 md:mb-0">
        <img src="/assets/images/carga-db-icon.svg" class="w-8 mx-auto mb-6" alt="">
        <h3 class="font-semibold text-2xl md:text-xl lg:text-2xl leading-tight mb-1">1. Carga tu base de datos</h3>
        <p class="leading-tight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
        <img src="/assets/images/carga-db-mockup.png" class="w-full mt-10 md:mt-20" alt="">
      </div>
      <div data-aos="fade-up" data-aos-delay="200" class="w-full md:w-4/12 lg:w-3/12 text-center px-4 mb-8 md:mb-0">
        <img src="/assets/images/configura-campana-icon.svg" class="w-8 mx-auto mb-6" alt="">
        <h3 class="font-semibold text-2xl md:text-xl lg:text-2xl leading-tight mb-1">2. Configura la campaña</h3>
        <p class="leading-tight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
        <img src="/assets/images/configura-campana-mockup.png" class="w-full mt-10 md:mt-20" alt="">
      </div>
      <div data-aos="fade-up" data-aos-delay="400" class="w-full md:w-4/12 lg:w-3/12 text-center px-4 mb-8 md:mb-0">
        <img src="/assets/images/resultados-icon.svg" class="w-8 mx-auto mb-6" alt="">
        <h3 class="font-semibold text-2xl md:text-xl lg:text-2xl leading-tight mb-1">3.Visualiza el resultado</h3>
        <p class="leading-tight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
        <img src="/assets/images/resultados-mockup.png" class="w-full mt-10 md:mt-20" alt="">
      </div>
    </div>
  </div>
</div>
