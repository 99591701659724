<div class="section-blog">
  <div id="blog" class="container mx-auto px-6 lg:px-3">
    <div class="flex flex-wrap items-center justify-center -mx-4">
      <div data-aos="fade-up" class="w-full mb-10">
        <h2 class="font-bold text-5xl text-center">Blog</h2>
      </div>
      <div data-aos="fade-up" data-aos-delay="" class="w-full md:w-4/12 lg:w-3/12 px-2 lg:px-4 mb-6 lg:mb-0">
        <app-blog-card></app-blog-card>
      </div>
      <div data-aos="fade-up" data-aos-delay="200" class="w-full md:w-4/12 lg:w-3/12 px-2 lg:px-4 mb-6 lg:mb-0">
        <app-blog-card></app-blog-card>
      </div>
      <div data-aos="fade-up" data-aos-delay="400" class="w-full md:w-4/12 lg:w-3/12 px-2 lg:px-4 mb-6 lg:mb-0">
        <app-blog-card></app-blog-card>
      </div>
    </div>
  </div>
</div>
