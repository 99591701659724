<div class="hidden lg:block section-clients mt-32">
  <div class="container mx-auto px-3">
    <div class="w-full mb-3" data-aos="fade-up">
      <span class="block text-center text-lg">Nuestros clientes</span>
    </div>
    <div class="w-full md:w-10/12 mx-auto" data-aos="fade-up">
      <div class="flex flex-wrap items-center py-6">
        <img src="/assets/images/divider-light.svg" class="w-full select-none">
        <div class="w-1/2 py-6 px-4">
          <img src="/assets/images/clients-logos-01.png" alt="">
        </div>
        <div class="w-1/2 py-6 px-4">
          <img src="/assets/images/clients-logos-02.png" alt="">
        </div>
        <img src="/assets/images/divider-light.svg" class="w-full select-none">
      </div>
    </div>
  </div>
</div>
