<div class="section-plans">
  <div class="container mx-auto px-3 hidden md:block">
    <div class="w-full md:w-10/12 mx-auto">
      <img src="/assets/images/divider.svg" class="w-full select-none">
    </div>
  </div>

  <div class="pt-4 md:pt-20 pb-16">
    <div id="planes" class="container mx-auto px-6 md:px-3">
      <div class="flex flex-wrap">
        <div class="w-full" data-aos="fade-up">
          <h2 class="font-bold text-5xl text-center mb-4">Elige tu plan</h2>
          <p class="text-center hidden md:block">Sin sorpresas, solo precios transparentes y honestos.<br>Seleccione un plan con las características que desee o compare los planes disponibles.</p>
        </div>
      </div>
      <div class="flex flex-wrap justify-center mt-3 md:mt-16">
        <div
          *ngFor="let plan of plans; let i = index"
          class="w-full md:w-4/12 lg:w-3/12 px-3 mb-6 md:mb-0"
          data-aos="fade-up"
          [attr.data-aos-delay]="i * 200"
        >
          <app-plan-card [plan]="plan"></app-plan-card>
        </div>
      </div>
    </div>
  </div>
</div>
