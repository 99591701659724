<div id="top" class="section-portrait pt-16 pb-36">
  <div class="container mx-auto px-6 lg:px-3">
    <div class="flex flex-wrap -mx-3">
      <div class="w-full md:max-w-md lg:max-w-none lg:w-6/12 ml-auto px-3 lg:pt-16 mr-auto lg:mr-0">
        <h1 class="text-4xl md:text-5xl lg:text-7xl font-bold leading-tight">Cobre más rápido con notifai.</h1>
        <form action="" class="mt-10">
          <div class="block w-full lg:max-w-xs mb-3">
            <input type="tel" class="form-control w-full" placeholder="Celular">
            <span class="block text-xs text-red-400 mt-1">Mensaje de error de ejemplo</span>
          </div>
          <div class="block w-full lg:max-w-xs mb-3">
            <input type="text" class="form-control w-full" placeholder="Nombre">
          </div>
          <label for="" class="flex items-center mb-3 md:max-w-xs">
            <input type="checkbox">
            <span class="text-xs opacity-50 ml-3">He leído y acepto las políticas de privacidad</span>
          </label>
          <button type="submit" class="btn btn-primary w-full lg:max-w-xs">Solicitar Llamada</button>
        </form>
      </div>
      <div class="w-5/12 px-3 hidden lg:block">
        <div class="portrait-mockup overflow-hidden">
          <img class="portrait-mockup__floating voice" src="/assets/images/portrait-voice.svg">
          <img class="portrait-mockup__floating client" src="/assets/images/portrait-cliente.svg">
          <img class="portrait-mockup__floating notifai" src="/assets/images/portrait-notifai.svg">
          <img class="portrait-mockup__floating whatsapp" src="/assets/images/portrait-whatsapp.svg">
          <img src="/assets/images/iphone-mockup.png" alt="mockup teléfono" class="max-w-xs mx-auto">
        </div>
      </div>
    </div>
  </div>
</div>
