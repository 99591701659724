<app-header></app-header>

<div class="flex flex-col">
  <app-portrait></app-portrait>

  <app-sponsors></app-sponsors>

  <app-how-it-works></app-how-it-works>

  <app-clients></app-clients>

  <app-results></app-results>

  <app-plans></app-plans>

  <app-discovery></app-discovery>

  <app-contact></app-contact>

  <app-clients-saying></app-clients-saying>

  <app-blog></app-blog>
</div>

<app-footer></app-footer>
