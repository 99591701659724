import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  plans = [
    {
      name: 'Bronce',
      price: '129.99',
      recommended: false,
      options: [
        { text: 'Plataforma de cobranzas SaaS', included: true },
        { text: 'Reporte de Cobranza', included: true },
        { text: 'Llamadas Masivas', included: true },
        { text: 'Llamadas Manuales', included: true },
        { text: 'Voz Humana', included: true },
        { text: 'Integración de Tecnología', included: false },
        { text: 'Infraestructura del Cliente', included: false },
        { text: 'Análisis Predictivo', included: false }
      ]
    },
    {
      name: 'Plata',
      price: '149.99',
      recommended: true,
      options: [
        { text: 'Plataforma de cobranzas SaaS', included: true },
        { text: 'Reporte de Cobranza', included: true },
        { text: 'Llamadas Masivas', included: true },
        { text: 'Llamadas Manuales', included: true },
        { text: 'Voz Humana', included: true },
        { text: 'Integración de Tecnología', included: false },
        { text: 'Infraestructura del Cliente', included: false },
        { text: 'Análisis Predictivo', included: false }
      ]
    },
    {
      name: 'Oro',
      price: '199.99',
      recommended: false,
      options: [
        { text: 'Plataforma de cobranzas SaaS', included: true },
        { text: 'Reporte de Cobranza', included: true },
        { text: 'Llamadas Masivas', included: true },
        { text: 'Llamadas Manuales', included: true },
        { text: 'Voz Humana', included: true },
        { text: 'Integración de Tecnología', included: false },
        { text: 'Infraestructura del Cliente', included: false },
        { text: 'Análisis Predictivo', included: false }
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
