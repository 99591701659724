<div class="section-clients-saying">
  <div class="container mx-auto px-6 lg:px-3 pt-4 md:pt-12 pb-12 md:pb-0 md:-mb-40 relative z-10">
    <div class="flex flex-wrap justify-center -mx-4">
      <div class="w-full md:w-1/2 lg:w-5/12 pt-10 md:pt-0 px-4">
        <img src="/assets/images/quote-mark.svg" class="-mb-16 -ml-24 select-none hidden md:block" alt="">
        <h2 data-aos="fade-up" class="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-8 md:mb-3 text-white md:text-black text-center md:text-left">
          Nuestros clientes dicen...
        </h2>
        <p data-aos="fade-up" class="text-lg mb-8 hidden md:block">Algunas historias de éxito.</p>
        <div data-aos="fade-up" class="bg-white shadow-lg p-5 rounded-lg md:max-w-xs ml-auto">
          <img src="/assets/images/hubspot-logo.png" class="h-10 mb-6" alt="">
          <div class="flex items-start">
            <img src="/assets/images/quote-mark-icon.svg" alt="" class="mr-3">
            <p class="text-lg mb-5 mt-1">Notifai ha incrementado mi tasa de cobrabilidad. 100% recomendado!</p>
          </div>
          <span class="block text-lg font-bold leading-tight">Nombre</span>
          <span class="text-sm" style="color:#969BAB">TITULO</span>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-5/12 pt-12 md:pt-20 px-4">
        <div data-aos="fade-up" class="bg-white shadow-lg p-5 rounded-lg md:max-w-md mr-auto mb-12">
          <img src="/assets/images/airbnb-logo.png" class="h-10 mb-5" alt="">
          <div class="flex items-start">
            <img src="/assets/images/quote-mark-icon.svg" alt="" class="mr-3">
            <p class="text-lg mb-5 mt-1">Notifai ha incrementado mi tasa de cobrabilidad. 100% recomendado!</p>
          </div>
          <span class="block text-lg font-bold leading-tight">Nombre</span>
          <span class="text-sm" style="color:#969BAB">TITULO</span>
        </div>
        <div data-aos="fade-up" class="bg-white shadow-lg p-5 rounded-lg md:max-w-xs mr-auto">
          <img src="/assets/images/bms-logo.png" class="h-10 mb-5" alt="">
          <div class="flex items-start">
            <img src="/assets/images/quote-mark-icon.svg" alt="" class="mr-3">
            <p class="text-lg mb-5 mt-1">Notifai ha incrementado mi tasa de cobrabilidad. 100% recomendado!</p>
          </div>
          <span class="block text-lg font-bold leading-tight">Nombre</span>
          <span class="text-sm" style="color:#969BAB">TITULO</span>
        </div>
      </div>
    </div>
  </div>
</div>
