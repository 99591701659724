<div class="section-contact" style="background-image:url('/assets/images/contact-bg.svg')">
  <div class="container mx-auto px-6 lg:px-3 pt-12 md:pt-20 pb-52 md:pb-20">
    <div class="flex flex-wrap justify-center">
      <div data-aos="fade-up" data-aos-delay="" class="w-full md:w-1/2 lg:w-5/12 pt-10">
        <h2 class="text-5xl font-bold leading-tight mb-6 md:mb-3">
          Contáctanos
        </h2>
      </div>
      <div data-aos="fade-up" data-aos-delay="200" class="w-full md:w-1/2 lg:w-5/12">
        <input type="text" class="form-control form-control--gray w-full mb-5" placeholder="Tu nombre">
        <input type="email" class="form-control form-control--gray w-full mb-5" placeholder="Tu correo electrónico">
        <textarea class="form-control form-control--gray w-full mb-5" placeholder="Tu mensaje" rows="4"></textarea>
        <button type="submit" class="btn btn-primary" style="border-radius: 1.5rem">Enviar</button>
      </div>
    </div>
  </div>
</div>
