import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PortraitComponent } from './sections/portrait/portrait.component';
import { SponsorsComponent } from './sections/sponsors/sponsors.component';
import { HowItWorksComponent } from './sections/how-it-works/how-it-works.component';
import { ClientsComponent } from './sections/clients/clients.component';
import { ResultsComponent } from './sections/results/results.component';
import { PlansComponent } from './sections/plans/plans.component';
import { ContactComponent } from './sections/contact/contact.component';
import { ClientsSayingComponent } from './sections/clients-saying/clients-saying.component';
import { BlogComponent } from './sections/blog/blog.component';
import { DiscoveryComponent } from './sections/discovery/discovery.component';

@NgModule({
  declarations: [
    AppComponent,
    PlanCardComponent,
    BlogCardComponent,
    HeaderComponent,
    FooterComponent,
    PortraitComponent,
    SponsorsComponent,
    HowItWorksComponent,
    ClientsComponent,
    ResultsComponent,
    PlansComponent,
    ContactComponent,
    ClientsSayingComponent,
    BlogComponent,
    DiscoveryComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
