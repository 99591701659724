<div class="blog-card">
  <a href="#">
    <div class="blog-card__thumbnail">
      <img src="/assets/images/blog-image.jpg" alt="">
    </div>
  </a>
  <div class="bg-white px-4 pt-4 pb-8">
    <a href="#">
      <h4 class="text-3xl lg:text-4xl font-bold leading-tight mb-2">Título</h4>
    </a>
    <p class="text-sm leading-tight" style="color:#718096;">En la era en la que vivimos se ha vuelto una necesidad el brindar un servicio de soporte automatizado, inmediato y personalizado. Según estudios realizados, el 86% de los clientes pagarían más por una mejor experiencia.</p>
    <div class="flex justify-between items-center mt-8">
      <span class="text-sm" style="color:#718096;">20 Mayo 2021</span>
      <a href="#" class="font-bold text-sm hover:underline">Leer más</a>
    </div>
  </div>
</div>
