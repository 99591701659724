<div
  class="plan-card bg-white rounded-xl p-6"
  [class.recommended]="plan.recommended"
>
  <div class="plan-card__header">
    <span *ngIf="plan.recommended" class="plan-card__recommended text-primary font-bold text-sm absolute top-0 left-0">RECOMENDADO</span>
    <div class="flex justify-between items-end">
      <span class="font-heading font-bold text-2xl lg:text-3xl">${{ plan.price }}</span>
      <span style="color:#A5ADBB">al mes</span>
    </div>
    <span class="block mt-2 mb-4 text-2xl text-primary font-serif">
      {{ plan.name }}
    </span>
  </div>
  <div class="plan-card__body">
    <ul class="plan-card-list">
      <li
        *ngFor="let option of plan.options"
        class="plan-card-list__item"
        [class.included]="option.included"
      >
        <img *ngIf="option.included" src="/assets/images/tick-icon.svg">
        <span>{{ option.text }}</span>
      </li>
    </ul>
  </div>
  <div class="plan-card__footer">
    <a href="#" class="block btn btn-primary w-full text-center">CONTRATA</a>
  </div>
</div>
