import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-saying',
  templateUrl: './clients-saying.component.html',
  styleUrls: ['./clients-saying.component.scss']
})
export class ClientsSayingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
