<footer class="main-footer pt-6 md:pt-12 pb-6 md:pb-0">
  <div class="container mx-auto px-6 lg:px-3">
    <div class="flex flex-wrap">
      <div class="hidden md:block w-4/12 ml-auto">
        <iframe class="rounded-xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.291273527453!2d-77.02837814976229!3d-12.092201191394942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c86415378347%3A0xfe1d87a31c7fb446!2sinterior%201002%2C%20Calle%20Amador%20Merino%20Reyna%20465%2C%20San%20Isidro%2015046!5e0!3m2!1sen!2spe!4v1624375039810!5m2!1sen!2spe" width="100%" height="260" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
      <div class="hidden md:block w-5/12 lg:w-4/12 pt-4 pl-10 pr-12 lg:pr-0">
        <ul class="icons-list mb-10">
          <li class="icons-list__item mb-6">
            <div class="icons-list__icon">
              <img src="/assets/images/location-primary-icon.svg" alt="">
            </div>
            <p class="text-white">Dirección: Calle Amador Merino Reyna N° 465 interior 1002, San Isidro - Lima - Perú</p>
          </li>
          <li class="icons-list__item mb-6">
            <div class="icons-list__icon">
              <img src="/assets/images/phone-primary-icon.svg" alt="">
            </div>
            <a href="#" class="text-white hover:underline">(511) 412 0570</a>
          </li>
          <li class="icons-list__item mb-6">
            <div class="icons-list__icon">
              <img src="/assets/images/envelope-primary-icon.svg" alt="">
            </div>
            <a href="#" class="text-white hover:underline">servicios@mdp.com.pe</a>
          </li>
        </ul>
        <div class="flex flex-wrap items-center -mx-3">
          <a href="#" class="mx-3">
            <img src="/assets/images/twitter-outlined.svg" class="h-10" alt="">
          </a>
          <a href="#" class="mx-3">
            <img src="/assets/images/facebook-filled.svg" class="h-10" alt="">
          </a>
          <a href="#" class="mx-3">
            <img src="/assets/images/linkedin-filled.svg" class="h-10" alt="">
          </a>
        </div>
      </div>
      <div class="w-full md:w-3/12 lg:w-2/12 pt-4 ml-auto">
        <ul>
          <h3 class="text-2xl text-white font-bold mb-4">Notifai</h3>
          <li class="mb-6">
            <a href="#" class="text-white hover:underline font-heading">¿Qué es Notifai?</a>
          </li>
          <li class="mb-6">
            <a href="#como-funciona" class="text-white hover:underline font-heading">¿Cómo funciona?</a>
          </li>
          <li class="mb-6">
            <a href="#planes" class="text-white hover:underline font-heading">Planes y Precios</a>
          </li>
          <li class="mb-6">
            <a href="#" class="text-white hover:underline font-heading">Conversa con un experto</a>
          </li>
          <li class="mb-6">
            <a href="#" class="text-white hover:underline font-heading">¿Quiénes somos?</a>
          </li>
        </ul>
      </div>
      <div class="w-full lg:w-11/12 ml-auto pt-6 pb-10">
        <p class="block md:hidden text-xs text-white">Copyright © Notifai 2021<br>Todos los derechos reservados.</p>
        <p class="hidden md:block text-xs text-white">Copyright © Notifai 2021 Todos los derechos reservados.</p>
      </div>
    </div>
  </div>
</footer>
<div class="bg-white pb-4 flex">
  <div class="bg-white p-2 mx-auto -mt-6">
    <img src="/assets/images/mdp-logo.png" class="h-16" alt="">
  </div>
</div>
