<div class="bg-primary py-12 section-discovery">
  <div class="container mx-auto px-3">
    <div class="flex flex-wrap items-center">
      <div data-aos="fade-up" data-aos-delay="" class="w-full md:w-5/12 ml-auto">
        <h2 class="text-4xl lg:text-5xl font-bold leading-tight text-white mb-3 px-8 md:px-0">
          Descubre como Notifai puede ayudarte.
        </h2>
        <p class="text-lg text-white my-10 md:my-0 px-8 md:px-0">Conversa con un experto ahora.</p>
      </div>
      <div data-aos="fade-up" data-aos-delay="200" class="w-full md:w-6/12">
        <img src="/assets/images/macbook-mockup.png" class="w-full -mr-2" alt="">
      </div>
    </div>
  </div>
</div>
